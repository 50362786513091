export const postRedirect = (url, params) => {
  const form = document.createElement('form')
  form.setAttribute('action', url)
  form.setAttribute('method', 'post')

  Object.keys(params).forEach(key => {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', key)
    input.setAttribute('value', params[key])

    form.appendChild(input)
  })

  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)

  return false
}

export const removeQueryStringParameter = (param) => {
  const replace = `[?&]${param}=[^&]+`
  const re = new RegExp(replace, 'g')

  history.replaceState && history.replaceState(
    null, '', location.pathname + location.search.replace(re, '').replace(/^&/, '?')
  )
}

/**
 * @param {number} milliseconds
 * @returns {Promise<void>}
 */
export const sleep = (milliseconds = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, milliseconds)
  })
}
