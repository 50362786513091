import * as winston from 'winston'
import BrowserConsole from 'winston-transport-browserconsole'
import { BugsnagTransport } from 'winston-3-bugsnag-transport'

// https://github.com/winstonjs/winston/issues/1354#issuecomment-426433071
import 'setimmediate'

const bugsnagEnabled = !!process.env.VUE_APP_BUGSNAG_API_KEY

const transports = [
  new BrowserConsole(
    {
      level: 'debug',
      format: winston.format.simple(),
    }
  )
]

if (bugsnagEnabled) {
  transports.push(new BugsnagTransport({
    level: 'warn',
    bugsnag: { apiKey: process.env.VUE_APP_BUGSNAG_API_KEY }
  }))
}

export default winston.createLogger({
  transports
})
