<template>
  <div id="subscription-modal" class="uk-modal-container" uk-modal>
    <div class="uk-modal-dialog">
      <validation-observer ref="validation" v-slot="{ failed }">
        <button class="uk-modal-close-default uk-modal-close-full" type="button" uk-close v-if="!loading"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Suscribirse al evento</h2>
        </div>

        <div uk-overflow-auto>
          <div class="uk-modal-body">
            <fieldset class="uk-fieldset">
              <div v-if="view === VIEW_USER">
                <validation-provider
                    vid="event"
                    name="evento"
                    mode="lazy"
                    rules="required"
                    v-slot="{ errors }">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="event">Evento</label>
                    <select
                        id="event"
                        class="uk-select"
                        :class="{'uk-form-danger': !!errors.length}"
                        uk-tooltip="Selecciona un evento"
                        v-model="subscription.event"
                    >
                      <option v-for="event in events"
                              :key="event._id"
                              :value="event"
                      >
                        {{ event.category.name }},
                        {{ event.isVirtual ? 'Virtual (Internet)' : event.address.fullAddress }},
                        {{ event.startsAt | moment('DD-MM-YYYY, HH:mm') }}
                      </option>
                    </select>
                    <div class="uk-text-small uk-text-danger"
                         v-if="!!errors.length"
                    >{{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
                <user-data-view v-model="subscription"></user-data-view>
              </div>

              <payment-view
                  v-if="view === VIEW_PAYMENT"
                  v-model="subscription"
                  @complete="complete($event)"
                  @pending="wait($event)"
              ></payment-view>

              <order-view
                  v-if="order && view === VIEW_ORDER"
                  :order-id="order._id"
              ></order-view>

              <gateway-view
                  v-if="order && view === VIEW_GATEWAY"
                  :order="order"
              ></gateway-view>
            </fieldset>
          </div>

          <div class="uk-modal-footer uk-text-right">
            <div class="uk-text-right" v-if="view === VIEW_USER">
              <button
                  type="button"
                  class="uk-button uk-button-primary"
                  :class="{'uk-button-danger': failed}"
                  aria-expanded="false"
                  uk-tooltip="Haciendo clic en SIGUIENTE, aceptas nuestra Política de Privacidad"
                  title=""
                  @click="validateAndGo(VIEW_PAYMENT)"
                  :disabled="loading"
              >Siguiente
              </button>
            </div>

            <div class="uk-text-right" v-if="view === VIEW_PAYMENT">
              <button
                  type="button"
                  class="uk-button uk-button-default"
                  aria-expanded="false"
                  title=""
                  @click="go(VIEW_USER)"
                  :disabled="loading"
              >Atrás
              </button>
            </div>

            <div class="uk-text-right" v-if="view === VIEW_ORDER">
              <button
                  type="button"
                  class="uk-button uk-button-default uk-modal-close"
                  aria-expanded="false"
                  title=""
              >Cerrar
              </button>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { PAYMENT_METHOD_COUPON, PAYMENT_METHOD_CREDIT_CARD, PRICE_ONE, PRICE_TWO } from '@/services/order'
import UserDataView from '@/components/UserDataView'
import UIkit from 'uikit'
import OrderView from '@/components/OrderView'
import GatewayView from '@/components/GatewayView'
import PaymentView from '@/components/PaymentView'

const VIEW_USER = 'user'
const VIEW_PAYMENT = 'payment'
const VIEW_ORDER = 'order'
const VIEW_GATEWAY = 'gateway'

export default {
  name: 'SubscriptionDialog',
  components: { GatewayView, OrderView, PaymentView, UserDataView },
  props: {
    events: {
      type: Array,
      default () {
        return []
      }
    },
    selectedEvent: {
      type: Object
    }
  },
  mounted () {
    this.subscriptionModal = UIkit.modal('#subscription-modal', {
      escClose: false,
      bgClose: false
    })
  },
  data () {
    return {
      loading: false,
      subscriptionModal: null,
      view: VIEW_USER,
      subscription: this.newSubscription({ event: this.selectedEvent }),
      order: null,
      VIEW_USER,
      VIEW_PAYMENT,
      VIEW_ORDER,
      VIEW_GATEWAY,
      PRICE_ONE,
      PRICE_TWO,
      PAYMENT_METHOD_COUPON,
      PAYMENT_METHOD_CREDIT_CARD,
    }
  },
  methods: {
    newSubscription (overrides = {}) {

      const debug = {
        /*event: null,
        user: {
          name: 'Diego Perez Barreiro',
          email: 'dperezbarreiro@gmail.com',
          telephone: '123456789'
        },
        guest: {
          name: 'Mauricio Wyler',
          email: 'mwyler@ebowe.com',
          telephone: '123456789'
        },
        price: 90,
        paymentMethod: PAYMENT_METHOD_COUPON,
        coupon: {
          code: 'THIS_IS_THE_COUPON_CODE',
          securityCode: 'THIS_IS_THE_SECURITY_CODE'
        },*/
        /*event: '60ae1e46f3b83ee85310d85b',
        user: {
          name: 'Diego Perez Barreiro',
          email: `dperezbarreiro+${Date.now()}@gmail.com`,
          telephone: '123456789'
        },
        price: 50,*/
      }

      return Object.assign({
        event: null,
        user: {},
        guest: {},
        price: null,
        paymentMethod: PAYMENT_METHOD_COUPON,
        coupon: {},
        relapse: {},
        ...debug,
      }, overrides)
    },
    show () {
      this.reset()
      this.subscriptionModal.show()
    },
    reset () {
      this.loading = false
      this.view = VIEW_USER
      this.subscription = this.newSubscription({ event: this.selectedEvent })
      this.order = null
      this.$refs.validation.reset()
    },
    go (view) {
      this.view = view
    },
    async validateAndGo (view) {
      const valid = await this.$refs.validation.validate()

      if (valid) {
        this.go(view)
      }
    },
    submit () {
    },
    complete (order) {
      this.order = order
      this.go(VIEW_ORDER)
    },
    wait (promise) {
      this.loading = true
      promise.finally(() => this.loading = false)
    }
  },
  watch: {
    selectedEvent (val) {
      this.subscription.event = val
    }
  }
}
</script>
