<template>
  <div class="user-data-view">
    <h4>Tus datos</h4>
    <validation-provider vid="name" name="nombres" mode="lazy" rules="required|max:255" v-slot="{ errors }">
      <div class="uk-margin">
        <label class="uk-form-label" for="name">Nombres</label>
        <input
            id="name"
            class="uk-input"
            :class="{'uk-form-danger': !!errors.length}"
            placeholder="Nombre y apellido"
            uk-tooltip="Escribe tu nombre y apellido"
            v-model="subscription.user.name" type="text">
        <div class="uk-text-small uk-text-danger"
             v-if="!!errors.length"
        >{{ errors[0] }}
        </div>
      </div>
    </validation-provider>

    <validation-provider vid="phone" name="teléfono" mode="lazy" rules="required|min:9|max:255" v-slot="{ errors }">
      <div class="uk-margin">
        <label class="uk-form-label" for="telephone">Teléfono</label>
        <input
            id="telephone"
            class="uk-input"
            :class="{'uk-form-danger': !!errors.length}"
            placeholder="Teléfono"
            uk-tooltip="Escribe un número válido para España, de 9 dígitos"
            v-model="subscription.user.telephone" type="text">
        <div class="uk-text-small uk-text-danger"
             v-if="!!errors.length"
        >{{ errors[0] }}
        </div>
      </div>
    </validation-provider>

    <validation-provider vid="email" name="correo" mode="lazy" rules="required|email" v-slot="{ errors }">
      <div class="uk-margin">
        <label class="uk-form-label" for="email">Correo</label>
        <input
            id="email"
            class="uk-input"
            :class="{'uk-form-danger': !!errors.length}"
            placeholder="E-mail"
            uk-tooltip="Escribe un correo electrónico"
            v-model="subscription.user.email" type="email">
        <div class="uk-text-small uk-text-danger"
             v-if="!!errors.length"
        >{{ errors[0] }}
        </div>
      </div>
    </validation-provider>

    <validation-provider vid="price" mode="lazy" rules="required" v-slot="{ errors }">
      <div class="uk-margin">
        <label class="uk-form-label" for="pricing">¿Irás con un amigo/a?</label>
        <select
            id="pricing"
            class="uk-select"
            :class="{'uk-form-danger': !!errors.length}"
            v-model="subscription.price">
          <option :value="null">Selecciona una opción</option>
          <option v-for="pricing in PRICING_OPTIONS"
                  :key="pricing.value"
                  :value="pricing.value"
          >
            {{ pricing.text }}
          </option>
        </select>
        <div class="uk-text-small uk-text-danger"
             v-if="!!errors.length"
        >Este campo es obligatorio
        </div>
      </div>
    </validation-provider>

    <!-- Guest -->
    <div v-if="subscription.price === PRICE_TWO">
      <h4>Datos de tu amigo/a</h4>

      <validation-provider vid="guest_name" name="nombres" mode="lazy" rules="required|max:255" v-slot="{ errors }">
        <div class="uk-margin">
          <label class="uk-form-label" for="guest-name">Nombres</label>
          <input
              id="guest-name"
              class="uk-input"
              :class="{'uk-form-danger': !!errors.length}"
              placeholder="Nombre y apellido"
              uk-tooltip="Escribe el nombre y apellido de tu amigo/a"
              v-model="subscription.guest.name" type="text">
          <div class="uk-text-small uk-text-danger"
               v-if="!!errors.length"
          >{{ errors[0] }}
          </div>
        </div>
      </validation-provider>

      <validation-provider vid="guest_phone" name="teléfono" mode="lazy" rules="required|min:9|max:255"
                           v-slot="{ errors }">
        <div class="uk-margin">
          <label class="uk-form-label" for="guest-telephone">Teléfono</label>
          <input
              id="guest-telephone"
              class="uk-input"
              :class="{'uk-form-danger': !!errors.length}"
              placeholder="Teléfono"
              uk-tooltip="Escribe un número válido para España, de 9 dígitos"
              v-model="subscription.guest.telephone" type="text">
          <div class="uk-text-small uk-text-danger"
               v-if="!!errors.length"
          >{{ errors[0] }}
          </div>
        </div>
      </validation-provider>

      <validation-provider vid="guest_email" name="correo" mode="lazy" rules="required|email" v-slot="{ errors }">
        <div class="uk-margin">
          <label class="uk-form-label" for="guest-email">Correo</label>
          <input
              id="guest-email"
              class="uk-input"
              :class="{'uk-form-danger': !!errors.length}"
              placeholder="E-mail"
              uk-tooltip="Escribe un correo electrónico"
              v-model="subscription.guest.email" type="email">
          <div class="uk-text-small uk-text-danger"
               v-if="!!errors.length"
          >{{ errors[0] }}
          </div>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import { SubscriptionFormView } from '@/mixins/subscription-form-view'
import { PRICE_TWO, PRICING_OPTIONS } from '@/services/order'

export default {
  name: 'UserDataView',
  mixins: [SubscriptionFormView],
  data () {
    return {
      PRICE_TWO,
      PRICING_OPTIONS
    }
  }
}
</script>
