<template>
  <div class="order-view">
    <div class="uk-overlay-default uk-position-cover" v-if="loading">
      <div class="uk-position-center">
        <div uk-spinner></div>
      </div>
    </div>

    <div v-if="order">
      <!-- Estado de la order -->
      <p v-if="order.status === STATUS_APPROVED">
        ¡Listo! Hemos confirmado tu pago y enviaremos un e-mail con los datos de la reserva.
      </p>
      <p v-if="order.status === STATUS_PENDING">
        Estamos procesando tu pago. Pronto te enviaremos un e-mail con los datos de la reserva.
      </p>
      <p v-if="order.status === STATUS_ERROR || order.status === STATUS_CANCELED">
        Tu pago no es válido, por favor contáctate con la administración.
      </p>

      <!-- Información cupón  -->
      <p v-if="order.paymentMethod === PAYMENT_METHOD_COUPON">
        Ten en cuenta que tu código de cupón y código de reserva son idénticos. 👍
      </p>

      <!-- Datos de la reserva -->
      <h3>Tu reserva</h3>
      <ul>
        <li>Categoría: {{ order.event.category.name }}.</li>
        <li>Fecha y hora: {{ order.event.startsAt | moment('DD-MM-YYYY, HH:mm') }}.</li>
        <li>Código de reserva: {{ order.reservationCode }}.</li>
        <li>Suscripto<span v-if="order.guest"> Nro 1</span>: {{ order.user.name }}.</li>
        <li v-if="order.guest">Suscripto Nro 2: {{ order.guest.name }}.</li>
        <li>Método de pago: {{ paymentMethodText(order.paymentMethod) }}</li>
        <li v-if="order.paymentMethod === PAYMENT_METHOD_CREDIT_CARD">
          Pago: {{ order.price }}€.
        </li>
      </ul>
      <p>
        Si no recibes el e-mail con la información de tu reserva, revisa el SPAM,
        <a href="https://www.hipnocentro.com/contacto/">contáctanos</a>
        o llama al +34 620 980 239. Nuestro horario de atención es de L a V, 10hrs a 16hrs.
      </p>
      <p>Ya puedes cerrar esta ventana.</p>
    </div>
  </div>
</template>

<script>
import {
  PAYMENT_METHOD_COUPON,
  PAYMENT_METHOD_CREDIT_CARD,
  paymentMethodText,
  STATUS_APPROVED,
  STATUS_CANCELED,
  STATUS_ERROR,
  STATUS_PENDING
} from '@/services/order'
import api from '@/services/api'
import UIkit from 'uikit'

export default {
  name: 'OrderView',
  props: {
    orderId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      loading: false,
      order: null,
      STATUS_APPROVED,
      STATUS_PENDING,
      STATUS_ERROR,
      STATUS_CANCELED,
      PAYMENT_METHOD_COUPON,
      PAYMENT_METHOD_CREDIT_CARD,
      paymentMethodText,
    }
  },
  methods: {
    checkOrderStatus (orderId) {
      this.loading = true

      api.get(`orders/${orderId}/check`)
          .then(({ data: { data } }) => this.order = data)
          .catch(() => UIkit.modal.alert('Ocurrió un error. Por favor, vuelve a intentarlo más tarde.'))
          .finally(() => this.loading = false)
    }
  },
  watch: {
    orderId: {
      handler (val) {
        if (!val || this.loading) {
          return
        }

        this.checkOrderStatus(val)
      },
      immediate: true,
    }
  }
}
</script>
