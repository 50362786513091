import Tag from 'vue-material-design-icons/Tag'
import CreditCard from 'vue-material-design-icons/CreditCard'
import CheckDecagram from 'vue-material-design-icons/CheckDecagram'
import BizumIcon from '@/components/BizumIcon'
import api from '@/services/api'

export const GATEWAY_REDSYS = 'redsys'
export const GATEWAY_PAYPAL = 'paypal'
export const GATEWAY_ADDON_PAYMENTS= 'addon_payments'
export const PRICE_ONE = 50
export const PRICE_TWO = 90
export const PAYMENT_METHOD_COUPON = 'coupon'
export const PAYMENT_METHOD_CREDIT_CARD = 'credit_card'
export const PAYMENT_METHOD_RELAPSE = 'relapse'
export const PAYMENT_METHOD_BIZUM = 'bizum'
export const PAYMENT_METHOD_PAYPAL = 'paypal'
export const PRICING_OPTIONS = [
  {
    text: 'Hipnosis para una persona (€50)',
    value: PRICE_ONE
  },
  {
    text: 'Hipnosis para dos personas (€90)',
    value: PRICE_TWO
  }
]
export const PAYMENT_METHODS = [{
  text: 'Cupón',
  value: PAYMENT_METHOD_COUPON,
  icon: Tag,
}, {
  text: 'Tarjeta de crédito/débito',
  value: PAYMENT_METHOD_CREDIT_CARD,
  icon: CreditCard,
}, {
  text: 'Garantía',
  value: PAYMENT_METHOD_RELAPSE,
  icon: CheckDecagram,
  onlyInCategories: ['dejar-de-fumar'],
  onlyVirtual: true,
}, {
  text: 'Bizum',
  value: PAYMENT_METHOD_BIZUM,
  icon: BizumIcon,
  iconOnly: true,
}]
export const STATUS_PENDING = 'pending'
export const STATUS_APPROVED = 'approved'
export const STATUS_CANCELED = 'canceled'
export const STATUS_ERROR = 'error'

export const paymentMethodText = (paymentMethodValue) => {
  return PAYMENT_METHODS.find(({ value }) => value === paymentMethodValue)?.text
}

export const placeOrder = ({ event, price, paymentMethod, user, guest, coupon, relapse, gateway }) => {
  // Preparar datos para el post
  const data = {
    event: event._id,
    price,
    paymentMethod,
    user,
    gateway,
  }

  // Agregar invitado
  if (price === PRICE_TWO) {
    data.guest = guest
  }

  // Agregar información adicional del método de pago
  switch (paymentMethod) {
    case PAYMENT_METHOD_COUPON:
      data.coupon = coupon
      break
    case PAYMENT_METHOD_RELAPSE:
      data.relapse = relapse
      break
  }

  return api.post('/orders/place', data)
    .then(({ data: { data } }) => data)
}
