import Vue from 'vue'
import App from './App.vue'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import { validCoupon } from '@/services/validation'
import VueStatic from 'vue-static'
import VTooltip from 'v-tooltip'

const bugsnagEnabled = !!process.env.VUE_APP_BUGSNAG_API_KEY

if (bugsnagEnabled) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()]
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
}

moment.tz.setDefault('Europe/Madrid')

Vue.use(VueMoment, {
  moment
})

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('validCoupon', validCoupon)

localize('es', es)

Vue.use(VueStatic)

Vue.use(VTooltip)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
