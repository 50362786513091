<template>
  <div class="events-list">
    <table id="events" class="uk-table uk-table-hover uk-table-divider">
      <thead>
      <tr>
        <th>Categoría</th>
        <th>Formato</th>
        <th>Lugar</th>
        <th>Fecha</th>
        <th>Horario</th>
      </tr>
      </thead>
      <tbody>
      <tr :class="event.category.name" v-for="event in events" :key="event.id" @click="openSubscriptionModal(event)"
          v-tooltip="{
            content: '¡Haz click para suscribirte a este evento!',
            delay: { show: 500, hide: 100 },
          }">
        <td>{{ event.category.name }}</td>
        <td>{{ event.isVirtual ? 'Virtual (Internet)' : 'Presencial' }}</td>
        <td>
          <span v-if="event.isVirtual">-</span>
          <span v-else><strong>{{ event.address.province }}.</strong> {{ event.address.location }},
              {{ event.address.dir }}</span>
        </td>
        <td style="white-space: nowrap;">{{ event.startsAt | moment('DD-MM-YYYY') }}</td>
        <td style="white-space: nowrap;">{{ event.startsAt | moment('HH:mm') }}</td>
      </tr>
      </tbody>
    </table>

    <!-- Empty case -->
    <p v-if="empty" class="uk-text-center">
      En este momento no hay seminarios disponibles.
    </p>

    <!-- Error case -->
    <p v-if="error" class="uk-text-center">
      Lo sentimos mucho, estamos teniendo dificultades técnicas en este momento.
      Por favor, inténtalo nuevamente en unos minutos.
    </p>

    <subscription-dialog ref="subscriptionDialog" :events="events" :selected-event="selectedEvent">
    </subscription-dialog>

    <order-dialog ref="orderDialog"></order-dialog>
  </div>
</template>

<script>
import parse from 'url-parse'
import SubscriptionDialog from '@/components/SubscriptionDialog'
import { EventsMixin } from '@/mixins/events'
import OrderDialog from '@/components/OrderDialog'
import logger from '@/services/logger'
import UIkit from 'uikit'
import { removeQueryStringParameter } from '@/services/utils'

export default {
  name: 'EventsList',
  components: { OrderDialog, SubscriptionDialog },
  mixins: [EventsMixin],
  mounted () {
    const url = parse(window.location, true)
    const orderId = url.query?.order_id
    const errorMessage = url.query?.error

    if (orderId) {
      this.openOrderModal(orderId)
      removeQueryStringParameter('order_id')
    }

    if (errorMessage) {
      UIkit.modal.alert(errorMessage, { stack: true })
      removeQueryStringParameter('error')
    }

    this.load()
  },
  data: () => ({
    loading: false,
    empty: false,
    error: false,
    subscriptionModal: null,
    events: [],
    selectedEvent: null,
    orderId: null
  }),
  methods: {
    load () {
      this.loading = true
      this.empty = false
      this.error = false

      this.getAvailableEvents().
          then(events => this.events = events).
          then(() => this.empty = !this.events.length).
          catch((e) => {
            this.error = true
            logger.error(e.message, e)
          }).
          finally(() => this.loading = false)
    },

    openSubscriptionModal (event) {
      this.selectedEvent = event
      this.$refs.subscriptionDialog.show()
    },

    openOrderModal (orderId) {
      this.$refs.orderDialog.show(orderId)
    }
  }
}
</script>
