import api from '@/services/api'

export const validCoupon = {
  validate (code) {
    return api.post('coupons/validate', { code })
      .then(({ data }) => {
        return data.data || 'El cupón ya ha sido utilizado'
      })
      .catch(() => true)
  }
}
