<template>
  <div id="app">
    <events-list></events-list>
  </div>
</template>

<script>
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import EventsList from '@/components/EventsList'

UIkit.use(Icons)

export default {
  name: 'App',
  components: {
    EventsList
  },
}
</script>

<style lang="scss">
//
// https://getuikit.com/docs/sass#how-to-build
//
// 1. Your custom variables and variable overwrites.
// $global-link-color: #DA7D02;

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
// @mixin hook-card() { color: #000; }

// 4. Import UIkit.
@import "uikit/src/scss/uikit-theme.scss";

.uk-modal {
  z-index: 9999;
}

.uk-button-primary {
  background: #ff9818
}

.uk-button-primary:hover,
.uk-button-primary:focus {
  background: #ffa02b;
  box-shadow: 0 0 5px #ff9818
}

.uk-button-primary:disabled:hover {
  background: transparent
}

.uk-alert-primary {
  background: #ff9818;
  color: #fff
}

.uk-alert-danger {
  background: #ff4e18;
  color: #fff
}

.uk-alert-primary {
  background: #ff9818;
  color: #fff
}

.uk-card-primary {
  background: #ff9818;
  color: #fff
}

#events {
  border: 1px solid #fa9110
}

#events thead {
  background: #ff9818
}

#events thead th {
  color: #fff
}

#events tbody tr {
  opacity: 0.9;
  cursor: pointer;
  color: #3f408b;
  padding: 20px
}

#events tbody tr:hover {
  opacity: 1;
  background-color: white !important;
}

#events tr.Adelgazar {
  border: 1px solid #ff9818;
  background: #fab865
}

#events tr.Dejar.de.fumar {
  border: 1px solid #ff9818;
  background: #f2cb9a
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: #3f408b;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #3f408b;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
