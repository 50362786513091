<template>
  <div id="order-modal" class="uk-modal-container" uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">Suscribirse al evento</h2>
      </div>

      <div class="uk-modal-body" uk-overflow-auto>
        <order-view :order-id="orderId"></order-view>
      </div>

      <div class="uk-modal-footer uk-text-right">
        <div class="uk-text-right">
          <button
              type="button"
              class="uk-button uk-button-default uk-modal-close"
              aria-expanded="false"
              title=""
          >Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
import OrderView from '@/components/OrderView'

export default {
  name: 'OrderDialog',
  components: { OrderView },
  mounted () {
    this.orderModal = UIkit.modal('#order-modal', {
      escClose: false,
      bgClose: false
    })
  },
  data () {
    return {
      orderId: null
    }
  },
  methods: {
    show (orderId) {
      this.orderId = orderId
      this.orderModal.show()
    },
  }
}
</script>
