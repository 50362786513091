import api from '@/services/api'

export const EventsMixin = {
  methods: {
    getAvailableEvents () {
      const params = {
        sort: 'startsAt',
        populate: 'category,address'
      }

      return api.get('/events/available', { params })
        .then(({ data: { data } }) => data)
    }
  }
}