<template>
  <div>
    <button class="uk-button uk-button-default uk-width-1-1 uk-margin-small-bottom"
            @click="$emit('select', paymentMethod)"
    >
      <span v-if="icon" :uk-icon="icon"></span> <span v-if="text">{{ text }}</span>
    </button>

    <div v-if="expanded" class="uk-margin-bottom">
      <div class="uk-text-right">
        <button type="button"
                class="uk-icon"
                :disabled="disabled"
                @click="$emit('cancel')"
        >
          <span uk-icon="close"></span>
        </button>
      </div>

      <ValidationObserver
          v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <slot></slot>
          <button
              type="submit"
              class="uk-button uk-button-primary uk-width-1-1"
              :disabled="disabled"
          >Comprar ahora
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentMethodButton',
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    paymentMethod: {
      type: String,
      default () {
        return 'default'
      },
    },
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    expanded: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
    }
  },
  methods: {
    onSubmit () {
      if (this.disabled) {
        return
      }

      this.$emit('submit', this.subscription)
    },
  }
}
</script>


