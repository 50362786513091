<template>
  <div class="addon-payments-button">
    <button
      id="addonPaymentsButton"
      class="uk-button uk-button-default uk-width-1-1 uk-margin-small-bottom"
      @click="$emit('select', PAYMENT_METHOD_CREDIT_CARD)"
    >
      <span uk-icon="credit-card"></span>
      <span>Tarjeta de crédito o débito</span>
    </button>

    <div v-if="expanded" class="uk-margin-bottom">
      <div class="uk-text-right">
        <button type="button" class="uk-icon" :disabled="disabled" @click="cancel">
          <span uk-icon="close"></span>
        </button>
      </div>

      <div v-show="view === VIEW_BILLING_ADDRESS">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <p class="uk-text-bold">Completa tu dirección de facturación para continuar con el pago.</p>

            <validation-provider
              vid="address_line_1"
              name="dirección 1"
              mode="lazy"
              :rules="{ required: true, max: 50, /* regex: cleanStringRe */ }"
              v-slot="{ errors }"
            >
              <div class="uk-margin">
                <label class="uk-form-label" for="address_line_1">Dirección 1*</label>
                <input
                  id="address_line_1"
                  class="uk-input"
                  :class="{ 'uk-form-danger': !!errors.length }"
                  placeholder="Calle y número"
                  v-model="address.line1"
                  type="text"
                />
                <div class="uk-text-small uk-text-danger" v-if="!!errors.length">{{ errors[0] }}</div>
              </div>
            </validation-provider>

            <validation-provider
              vid="address_line_2"
              name="dirección 2"
              mode="lazy"
              :rules="{ required: false, max: 50, /* regex: cleanStringRe */ }"
              v-slot="{ errors }"
            >
              <div class="uk-margin">
                <label class="uk-form-label" for="address_line_2">Dirección 2</label>
                <input
                  id="address_line_2"
                  class="uk-input"
                  :class="{ 'uk-form-danger': !!errors.length }"
                  placeholder="Piso y departamento"
                  v-model="address.line2"
                  type="text"
                />
                <div class="uk-text-small uk-text-danger" v-if="!!errors.length">{{ errors[0] }}</div>
              </div>
            </validation-provider>

            <validation-provider
              vid="city"
              name="ciudad"
              mode="lazy"
              :rules="{ required: true, max: 40, /* regex: cleanStringRe */ }"
              v-slot="{ errors }"
            >
              <div class="uk-margin">
                <label class="uk-form-label" for="city">Ciudad*</label>
                <input
                  id="city"
                  class="uk-input"
                  :class="{ 'uk-form-danger': !!errors.length }"
                  placeholder="Ciudad"
                  v-model="address.city"
                  type="text"
                />
                <div class="uk-text-small uk-text-danger" v-if="!!errors.length">{{ errors[0] }}</div>
              </div>
            </validation-provider>

            <validation-provider
              vid="zip_code"
              name="código postal"
              mode="lazy"
              :rules="{ required: true, max: 16, /* regex: cleanStringRe */ }"
              v-slot="{ errors }"
            >
              <div class="uk-margin">
                <label class="uk-form-label" for="zip_code">Código postal*</label>
                <input
                  id="zip_code"
                  class="uk-input"
                  :class="{ 'uk-form-danger': !!errors.length }"
                  placeholder="Código postal"
                  v-model="address.zipCode"
                  type="text"
                />
                <div class="uk-text-small uk-text-danger" v-if="!!errors.length">{{ errors[0] }}</div>
              </div>
            </validation-provider>

            <validation-provider
              vid="country_code"
              name="país"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="uk-margin">
                <label class="uk-form-label" for="country_code">País*</label>
                <select class="uk-select" id="country_code" v-model="address.countryCode">
                  <option
                    v-for="country in countries"
                    :key="country.numeric"
                    :value="country.numeric"
                  >{{ country.name }}</option>
                </select>
                <div class="uk-text-small uk-text-danger" v-if="!!errors.length">{{ errors[0] }}</div>
              </div>
            </validation-provider>

            <small>* Campos obligatorios</small>

            <button
              type="submit"
              class="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
              :disabled="disabled"
            >Continuar</button>
          </form>
        </ValidationObserver>
      </div>

      <div v-show="view === VIEW_PAYMENT">
        <iframe id="addonPaymentsIframe"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GATEWAY_ADDON_PAYMENTS,
  PAYMENT_METHOD_CREDIT_CARD,
} from '@/services/order'
import Countries from 'i18n-iso-countries'
import es from 'i18n-iso-countries/langs/es.json'
import api from '@/services/api'
import logger from '@/services/logger'
import { RealexHpp } from 'exports-loader?exports=RealexHpp!/node_modules/rxp-js/lib/rxp-hpp.js'
import UIkit from 'uikit'
import { sleep } from '@/services/utils'

Countries.registerLocale(es)

const names = Countries.getNames('es', { select: 'official' })
const countries = Object.keys(names)
  .map((alpha2) => {
    return {
      name: names[alpha2],
      numeric: Countries.alpha2ToNumeric(alpha2),
    }
  })
  .sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()

    if (nameA < nameB) {
      return -1
    }

    if (nameA > nameB) {
      return 1
    }

    return 0
  })

const VIEW_BILLING_ADDRESS = 'address'
const VIEW_PAYMENT = 'payment'

const defaultAddress = {
  /*line1: 'Paseo de la Audiencia, 7',
  line2: '',
  countryCode: '724',
  city: 'Burgos',
  zipCode: '09003',*/
  line1: '',
  line2: '',
  countryCode: '724',
  city: '',
  zipCode: '',
}

export default {
  name: 'AddonPaymentsButton',
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    createOrder: {
      type: Function,
      required: true,
    },
    expanded: {
      type: Boolean,
      default() {
        return false
      },
    },
    disabled: {
      type: Boolean,
    },
  },
  created: function () {
    window.addEventListener('message', this.listenAddonPaymentsMessages)
  },
  destroyed: function () {
    window.removeEventListener('message', this.listenAddonPaymentsMessages)
  },
  data() {
    return {
      PAYMENT_METHOD_CREDIT_CARD,
      VIEW_BILLING_ADDRESS,
      VIEW_PAYMENT,
      view: VIEW_BILLING_ADDRESS,
      address: Object.assign({}, defaultAddress),
      // cleanStringRe: /^[A-Za-z0-9 _\-.,!"']+$/,
    }
  },
  static() {
    return {
      countries,
    }
  },
  methods: {
    reset() {
      this.address = Object.assign({}, defaultAddress)
      this.view = VIEW_BILLING_ADDRESS
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    listenAddonPaymentsMessages(event) {
      if (
        new URL(event.origin).hostname !==
        new URL(process.env.VUE_APP_ADDON_PAYMENTS_HPP_URL).hostname
      ) {
        return
      }

      try {
        const data = JSON.parse(event.data)

        // When we receive an iframe update of 0px size, the iframe is about to redirect so we wait in pending state.
        if (
          data.iframe &&
          data.iframe.width === '0px' &&
          data.iframe.height === '0px'
        ) {
          this.$emit('pending', sleep(10000))
        }
      } catch (e) {
        logger.warn('Could not parse event data', e)
      }
    },
    onSubmit() {
      if (this.disabled) {
        return
      }

      const data = {
        ...this.subscription,
        address: this.address,
        gateway: GATEWAY_ADDON_PAYMENTS,
        paymentMethod: PAYMENT_METHOD_CREDIT_CARD,
      }

      const pending = Promise.resolve()
        .then(() => this.createOrder(data))
        .then((order) => {
          if (!order) {
            this.$emit('cancel')
            throw new Error('handled')
          }

          data.order = {
            _id: order._id,
          }

          return api
            .post('gateways/addon-payments/hpp-request', data)
            .then(({ data }) => {
              const hppUrl = process.env.VUE_APP_ADDON_PAYMENTS_HPP_URL

              const merchantUrl =
                process.env.VUE_APP_ADDON_PAYMENTS_MERCHANT_URL

              const serverSdkJson = data.data

              RealexHpp.setHppUrl(hppUrl)

              RealexHpp.embedded.init(
                'autoload',
                'addonPaymentsIframe',
                merchantUrl,
                serverSdkJson
              )

              return order
            })
        })
        .then(() => sleep(3000))
        .then(() => (this.view = VIEW_PAYMENT))
        .catch((err) => {
          // Error was handled in parent component
          if (err.message === 'handled') {
            return
          }

          logger.error(
            'Addon Payments error while creating order',
            err.response || err
          )

          UIkit.modal.alert(
            'Ocurrió un error. Por favor, vuelve a intentarlo más tarde.',
            { stack: true }
          )

          this.$emit('cancel')
        })

      this.$emit('pending', pending)

      return pending
    },
  },
}
</script>

<style>
#addonPaymentsIframe {
  width: 100%;
  min-height: 600px;
}
</style>
