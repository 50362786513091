<template>
  <div class="confirmation-view">
    <div class="uk-grid-match uk-grid-divider" uk-grid>
      <div class="uk-width-expand@m">
        <div>
          <p>
            A continuación puedes ver tus datos, para ver si está todo bien y
            completar tu suscripción.
          </p>

          <ul>
            <li>
              <strong>Seminario</strong>: Hipnosis para
              {{ subscription.event.category.name }} en
              <span v-if="subscription.event.isVirtual">Sala virtual</span>
              <span v-else>{{ subscription.event.address.fullAddress }}</span>,
              {{ subscription.event.startsAt | moment('DD-MM-YYYY, HH:mm') }}.
            </li>
          </ul>

          <h3>Tus datos</h3>
          <ul>
            <li><strong>Nombres</strong>: {{ subscription.user.name }}.</li>
            <li><strong>Correo</strong>: {{ subscription.user.email }}</li>
            <li>
              <strong>Teléfono</strong>: {{ subscription.user.telephone }}
            </li>
          </ul>

          <div v-if="subscription.price === PRICE_TWO">
            <h3>Datos de tu amigo/a</h3>
            <ul>
              <li><strong>Nombres</strong>: {{ subscription.guest.name }}.</li>
              <li><strong>Correo</strong>: {{ subscription.guest.email }}</li>
              <li>
                <strong>Teléfono</strong>: {{ subscription.guest.telephone }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="uk-width-1-3@m">
        <h3>Método de pago</h3>

        <div style="position: relative">
          <!-- Cupón -->
          <payment-method-button :subscription="subscription" :payment-method="PAYMENT_METHOD_COUPON" text="Cupón"
            icon="tag" :expanded="paymentMethod === PAYMENT_METHOD_COUPON" :disabled="pending"
            v-show="paymentMethodIsvisible(PAYMENT_METHOD_COUPON)" @select="selectPaymentMethod($event)"
            @submit="submit" @cancel="reset()">
            <validation-provider vid="coupon_code" name="código del cupón" mode="lazy"
              rules="required|max:255|validCoupon" v-slot="{ errors }">
              <div class="uk-margin">
                <label>Código de cupón</label>
                <input class="uk-input" :class="{ 'uk-form-danger': !!errors.length }" placeholder="Código del cupón"
                  v-model="subscription.coupon.code" type="text" />
                <div class="uk-text-small uk-text-danger" v-if="!!errors.length">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>

            <validation-provider vid="coupon_security_code" name="código de seguridad del cupón" mode="lazy"
              rules="required|max:255" v-slot="{ errors }">
              <div class="uk-margin">
                <label>Cód. de seguridad</label>
                <input class="uk-input" :class="{ 'uk-form-danger': !!errors.length }"
                  placeholder="Código de seguridad del cupón" v-model="subscription.coupon.securityCode" type="text" />
                <div class="uk-text-small uk-text-danger" v-if="!!errors.length">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
          </payment-method-button>

          <!-- Recaída -->
          <payment-method-button v-if="relapseAvailable" :subscription="subscription"
            :payment-method="PAYMENT_METHOD_RELAPSE" text="Garantía" icon="star"
            :expanded="paymentMethod === PAYMENT_METHOD_RELAPSE" :disabled="pending"
            v-show="paymentMethodIsvisible(PAYMENT_METHOD_RELAPSE)" @select="selectPaymentMethod($event)"
            @submit="submit" @cancel="reset()">
            <validation-provider vid="warranty_code" name="código de garantía" mode="lazy" rules="required|max:255"
              v-slot="{ errors }">
              <div class="uk-margin">
                <h3>Código de garantía</h3>
                <input class="uk-input" :class="{ 'uk-form-danger': !!errors.length }" placeholder="Código de garantía"
                  v-model="subscription.relapse.code" type="text" />
                <div class="uk-text-small uk-text-danger" v-if="!!errors.length">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
          </payment-method-button>

          <!-- Paypal -->
          <!-- <pay-pal-button v-if="paymentGateway === 'paypal'" :subscription="subscription" :create-order="createOrder"
            v-show="
              paymentMethodIsvisible(PAYMENT_METHOD_CREDIT_CARD) ||
              paymentMethodIsvisible(PAYMENT_METHOD_PAYPAL)
            " @select="selectPaymentMethod($event)" @complete="complete()" @cancel="reset()" @pending="wait($event)">
          </pay-pal-button> -->

          <!-- RedSys -->
          <!-- <red-sys-button v-if="paymentGateway === 'redsys'" :subscription="subscription" :create-order="createOrder"
            :expanded="paymentMethod === PAYMENT_METHOD_CREDIT_CARD" :disabled="pending"
            @select="selectPaymentMethod($event)" @complete="complete()" @cancel="reset()" @pending="wait($event)">
          </red-sys-button> -->

          <!-- Addon Payments -->
          <addon-payments-button v-if="paymentGateway === 'addon-payments'" :subscription="subscription"
            :create-order="createOrder" :expanded="paymentMethod === PAYMENT_METHOD_CREDIT_CARD" :disabled="pending"
            @select="selectPaymentMethod($event)" @complete="complete()" @cancel="reset()" @pending="wait($event)">
          </addon-payments-button>

          <div v-if="pending" class="uk-overlay-default uk-position-cover" style="z-index: 999">
            <div class="uk-position-center">
              <div uk-spinner></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SubscriptionFormView } from '@/mixins/subscription-form-view'
import {
  PAYMENT_METHOD_COUPON,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_PAYPAL,
  PAYMENT_METHOD_RELAPSE,
  PAYMENT_METHODS,
  placeOrder,
  PRICE_TWO,
} from '@/services/order'
import logger from '@/services/logger'
import UIkit from 'uikit'
import PaymentMethodButton from '@/components/PaymentMethodButton'
import AddonPaymentsButton from '@/components/AddonPaymentsButton'
// import PayPalButton from '@/components/PayPalButton'
// import RedSysButton from '@/components/RedSysButton.vue'

export default {
  name: 'ConfirmationView',
  components: { PaymentMethodButton, AddonPaymentsButton, /* PayPalButton, RedSysButton */ },
  mixins: [SubscriptionFormView],
  data() {
    return {
      pending: false,
      order: null,
      paymentMethod: null,
      paymentGateway: process.env.VUE_APP_PAYMENT_GATEWAY,
      PAYMENT_METHODS,
      PRICE_TWO,
      PAYMENT_METHOD_CREDIT_CARD,
      PAYMENT_METHOD_COUPON,
      PAYMENT_METHOD_RELAPSE,
      PAYMENT_METHOD_PAYPAL,
    }
  },
  methods: {
    paymentMethodIsvisible(paymentMethod) {
      if (!this.paymentMethod) {
        return true
      }

      return this.paymentMethod === paymentMethod
    },
    createOrder(data) {
      return placeOrder(data)
        .then((order) => (this.order = order))
        .catch((e) => {
          logger.error('Error placing order', e.response || e)

          let message =
            'Ocurrió un error. Por favor, vuelve a intentarlo más tarde.'

          // Captura de errores de validación.
          if (e.response && e.response.status === 400) {
            const errors = e.response.data?.error?.errors || []
            const first = errors[0]

            if (first) {
              switch (first.type) {
                case 'event.user_already_subscribed':
                  message =
                    '¡Ya estás suscripto a este seminario! No puedes suscribirte nuevamente'
                  break
                case 'event.guest_already_subscribed':
                  message =
                    '¡Tu amigo/a ya está suscripto/a a este seminario! No puedes suscribirse nuevamente'
                  break
                case 'event.full':
                  message = 'Lo sentimos, el seminario está completo'
                  break
              }
            }
          }

          UIkit.modal.alert(message, { stack: true })
        })
    },

    selectPaymentMethod(paymentMethod) {
      if (this.paymentMethod === paymentMethod) {
        return
      }

      this.paymentMethod = paymentMethod
    },

    reset() {
      this.paymentMethod = null
    },

    submit() {
      const data = {
        ...this.subscription,
        paymentMethod: this.paymentMethod,
      }

      return this.wait(
        this.createOrder(data).then((order) => !!order && this.complete())
      )
    },

    complete() {
      this.reset()
      this.$emit('complete', this.order)
    },

    wait(promise) {
      this.$emit('pending', promise)

      this.pending = true

      promise.finally((result) => {
        this.pending = false
        return result
      })

      return promise
    },
  },
  computed: {
    paymentMethodName() {
      const paymentMethodDef = this.PAYMENT_METHODS.find(
        (m) => m.value === this.subscription.paymentMethod
      )

      return paymentMethodDef?.text
    },
    relapseAvailable() {
      return (
        this.subscription.event.category?.slug === 'dejar-de-fumar' &&
        this.subscription.event.isVirtual
      )
    },
  },
}
</script>
