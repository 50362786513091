export const SubscriptionFormView = {
  props: {
    value: Object,
    required: true,
  },
  data () {
    return {
      subscription: this.value
    }
  },
  watch: {
    value: {
      handler (val) {
        this.$set(this, 'subscription', val)
      },
      deep: true
    },
    subscription: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true
    }
  }
}