<template>
  <img :src="bizum" alt="Logo Bizum" :height="size">
</template>

<script>
import bizum from '../assets/btn_horizontal200x95.png'

export default {
  name: 'BizumIcon',
  props: {
    size: Number,
    default () {
      return 24
    }
  },
  data: () => ({
    bizum,
  })
}
</script>
