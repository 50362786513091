<template>
  <div class="gateway-view">
    <div class="uk-overlay-default uk-position-cover">
      <div class="uk-position-center">
        <div uk-spinner></div>
      </div>
    </div>
  </div>
</template>

<script>
import { postRedirect } from '@/services/utils'

export default {
  name: 'GatewayView',
  props: {
    order: {
      type: Object,
      required: false,
    }
  },
  methods: {
    submit (order) {
      const { params } = order.gateway.request

      postRedirect(params.url, params.body)
    }
  },
  watch: {
    order: {
      handler (val) {
        if (val) {
          this.submit(val)
        }
      },
      immediate: true
    }
  }
}
</script>
